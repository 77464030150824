<template>
  <BPCard
  :headerConfig="{ title: this.$t('balances'), subtitle: $t('balancesCardSubtitle') }"
  hideTransfers
  :contractMode.sync="contractMode">
    <v-row>
      <v-col>
        <v-tabs
        v-model="currentTab"
        slider-color="secondary"
        color="#888888"
        slider-size="4">
          <v-tab>
            <span class="black--text" style="font-size: 16px;">
              {{ $t('advances') }}
            </span>
          </v-tab>
          <v-tab>
            <span class="black--text" style="font-size: 16px;">
              {{ $t('tractDepletion') }}
            </span>
          </v-tab>
          <v-tab v-if="!hideAdjustingEntries">
            <span class="black--text" style="font-size: 16px;">
              {{ $t('adjustingEntries') }}
            </span>
          </v-tab>
          <v-tab-item>
            <Advances/>
          </v-tab-item>
          <v-tab-item>
            <Depletion/>
          </v-tab-item>
          <v-tab-item>
            <AdjustingEntries
            :contractMode="contractMode"/>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </BPCard>
</template>

<script>
import { ContractModes, AdjustingEntriesFrequency } from '@/utils/Enumerations.js'
import { mapGetters } from 'vuex'
export default {
  name: 'Balances',
  components: {
    BPCard: () => import('@/components/core/BPCard.vue'),
    Advances: () => import('@/components/accounting/balances/Advances.vue'),
    AdjustingEntries: () => import('@/components/accounting/balances/AdjustingEntries.vue'),
    Depletion: () => import('@/components/accounting/balances/Depletion.vue')
  },

  data: () => ({
    contractMode: null,
    currentTab: 0
  }),

  watch: {
    currentTab (val) {
      if (val === 2) {
        this.contractMode = ContractModes[0]
        return
      }
      this.contractMode = null
    }
  },

  computed: {
    ...mapGetters('user', ['companyInfo']),
    hideAdjustingEntries () {
      const companyAdjustingEntriesFrequency = this.adjustingEntriesFrequency(this.companyInfo.adjustingEntriesFrequency)
      return companyAdjustingEntriesFrequency.value === AdjustingEntriesFrequency.None.value
    }
  },

  methods: {
    adjustingEntriesFrequency: (x) => AdjustingEntriesFrequency.forInt(x)
  }
}
</script>
